#stats {
    width: 15rem;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 2.5rem;
    left: 3rem;
    cursor: default;
}

h1 {
    font-size: 1.5rem;
    margin: 0;
}

h2 {
    font-size: 1rem;
    margin: 0.3rem 0;
}

#stats__lines {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
}

#stats__lines__thick {
    display: block;
    top: 0;
    left: 0;
    background-color: white;
    height: 0.3rem;
    width: 2rem;
}

#stats__lines__thin {
    display: block;
    top: 0;
    left: 0;
    background-color: white;
    height: 0.1rem;
    width: 9.6rem;
    background-color: lightgrey;
}

@media (max-width: 1024px) {
    #stats {
        width: 8rem;
        top: 2rem;
    }

    h1 {
        font-size: 1rem;
    }

    h2, h3, p {
        font-size: 0.75rem;
    }
}

@media (max-width: 700px) {
    #stats {
        width: 8rem;
        top: 1.5rem;
    }

    h1 {
        font-size: 1rem;
    }

    h2, h3, p {
        font-size: 0.7rem;
    }
}
.avatar {
    position: fixed;
    overflow: hidden;
    z-index: -4;
}

.avatar.about {
    height: 70vh;
    bottom: -8vh;
    left: 50%;
    transform: translateX(-60%);
    z-index: -4;
}

.avatar.skills {
    height: 100vh;
    bottom: -20vh;
    left: 50%;
    transform: translateX(-60%) scaleX(-1);
    z-index: -4;
}

.shadow-overlay-skills, .shadow-overlay-about {
    height: 100vh;
    width: 100vh;
    display: block;
    position: fixed;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
    z-index: -3;
}

@media (max-width: 1440px) {
    .avatar.about {
        height: 70vh;
        bottom: -10vh;
        transform: translateX(-70%);
    }
}

@media (max-width: 1024px) {
    .avatar.about {
        height: 55vh;
        bottom: 0vh;
    }

    .avatar.skills {
        height: 70vh;
        bottom: -10vh;
        transform: translateX(-60%) scaleX(-1);
    }

    
    .shadow-overlay-skills, .shadow-overlay-about {
        top: 65vh;
        left: -10vh;
        height: 40vh;
        width: 150vw;
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1) 80%);
        z-index: -3;
    }
}

@media (max-width: 700px) {
    .avatar.about {
        height: 50vh;
        bottom: 0vh;
        transform: translateX(-58%);
    }

    .avatar.skills {
        height: 65vh;
        transform: translateX(-45%) scaleX(-1);
        bottom: -15vh;
    }

    .shadow-overlay-skills, .shadow-overlay-about {
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1) 60%);
    }
}
.skill-menu {
    width: 30rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 30%;
    right: 15vw;
    align-items: flex-end;
}

.skill-item {
    height: 4rem;
    width: 50%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.skill-title {
    font-size: 0.8rem;
    padding-right: 4rem;
}

.skill-item.activeSkill .skill-title {
    font-size: 1.2rem;
}

.activeSkill {
    border-radius: var(--radius-left);
    cursor: default;
}

.skill-item.activeSkill, .skill-item.activeSkill:hover {
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
}

.skill-icon {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 2rem;
    left: 8rem;
}

.skill-sub-container-1 h3,
.skill-sub-container-2 h3,
.skill-sub-container-3 h3 {
    position: relative;
    margin: 0;
}

.menu {
    width: 20rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-25%);
    right:  15vw;;
}

.skill-sub-container-1,
.skill-sub-container-2,
.skill-sub-container-3 {
    display: flex;
    justify-content: right;
    gap: 2rem;
}

.level-container {
    display: flex;
    gap: 0.3rem;
}

.level-point {
    width: 2rem;
    height: 1rem;
    border-radius: 0.2rem;
}

.filled {
    background: linear-gradient(180deg, #236473, #25afce, #7ce7ff);
}

.unfilled {
    background: linear-gradient(180deg, #1c8298, #074653, #074654);
}

.skill-sub-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

@media (max-width: 1440px) {
    .skill-menu {
        right: 10vw;
    }
    .menu {
        width: 15rem;
        top: 50%;
        transform: translateY(-90%);
        left: 5vw;
    }
}

@media (max-width: 1024px) {
    .menu {
        width: 18rem;
        top: 25vh;
        transform: translateY(-50%);
    }

    .skill-menu {
        top: 20vh;
    }

    .skill-item {
        height: 3rem;
        width: 50%;
    }
    
    .skill-title {
        font-size: 0.65rem;
        padding-right: 4.5rem;
    }
    
    .skill-item.activeSkill .skill-title {
        font-size: 1.1rem;
    }
    
    
    .skill-icon {
        width: 4rem;
        height: 4rem;
    }

}

@media (max-width: 700px) {
    .menu {
        left: 50%;
        transform: translateX(-5%);
        top: 5%;
        width: 10rem;
    }
    
    .menu .item {
        height: 2rem;
    }
    
    .menu .title {
        font-size: 0.4rem;
    }
    .menu .item.active .title {
        font-size: 0.8rem;
    }

    .skill-menu {
        top: 15%;
    }

    .skill-item {
        height: 2rem;
        width: 40%;
    }

    .skill-title {
        font-size: 0.5rem;
        padding-right: 4.5rem;
    }

    .skill-item.activeSkill .skill-title {
        font-size: 0.8rem;
    }

    .skill-icon {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 2rem;
        left: 12rem;
    }

}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&family=Russo+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;700&display=swap');

body {
    font-family: "Russo One", sans-serif;
    color: white;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition);
}

h3 {
    font-family: "Kanit", sans-serif;
}

p {
    font-family: "Poppins", sans-serif;
}

:root {
    --purple: #540351;
    --blue: #3627b1;
    --cyan: #25afce;
    --transparent: #00000000;
    --radius-left: 3rem 0 0 3rem;
    --radius-right: 0 3rem 3rem 0;
    --transition: all 400ms ease;
}

::-webkit-scrollbar {
    width: 0.5vw;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5vh;
}

button, 
.class-sub-container a, 
.project-item, 
.skill-item, 
.item:not(.active),
.sub-container-2:not(.active-subheading),
.sub-container-3:not(.active-subheading) {
    transition: var(--transition);
}

button:hover, 
.class-sub-container a:hover, 
.project-item:hover, 
.skill-item:hover, 
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover {
    background-image: linear-gradient(90deg, #ffffff00, #ffffff42 50%, #ffffff00);
}
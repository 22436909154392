.menu {
    width: 20rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 60%;
    transform: translateY(-60%);
    left: 10vw;
}

.item {
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.title {
    font-size: 1rem;
    padding-right: 2.5rem;
}

.item.active .title {
    font-size: 1.7rem;
}

.sub-container {
    width: 20rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 32%;
    right: 11vw;
}

.sub-container p {
    margin-left: 2rem;
    cursor: default;
}

.item.active,
.item.active:hover {
    background: linear-gradient(90deg, var(--transparent), var(--blue) 70%);
    border-radius: var(--radius-right);
    cursor: default;
}

.sub-container-1.active-subheading h3,
.sub-container-2.active-subheading h3,
.sub-container-3.active-subheading h3 {
    border-radius: var(--radius-left);
    background: linear-gradient(90deg, var(--purple), var(--transparent) 70%);
    padding: 1rem 3rem;
    cursor: default;
}

.sub-container-1:not(.active-subheading) h3,
.sub-container-2:not(.active-subheading) h3,
.sub-container-3:not(.active-subheading) h3 {
    opacity: 0.7;
    margin: 0;
    cursor: pointer;
}

.p-container {
    margin-top: 0.5rem;
    display: none;
    transition: var(--transition);
    max-height: 25vh;
}

.active-subheading .p-container {
    margin-top: 0.5rem;
    display: block;
    font-size: 1rem;
    overflow: auto;
}

.icon-title-container {
    display: flex;
    gap: 1.2rem;
    margin-bottom: 0.5rem;
    cursor: default;
}

.icon {
    width: 3rem;
    height: 3rem;
}

.sub-container-1 h3,
.sub-container-2,
.sub-container-3 {
    position: relative;
    margin: 0.2rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
}

@media (max-width: 1440px) {
    .sub-container {
        width: 20rem;
        top: 25%;
    }
}

@media (max-width: 1024px) {
    .sub-container {
        width: 20rem;
        top: 20%;
        right: 10vw;
        cursor: pointer;
    }

    .item.active .title {
        font-size: 1.2rem;
    }

}

@media (max-width: 700px) {
    .sub-container {
        width: 16rem;
        top: 22%;
        left: 50%;
        transform: translateX(-55%);
    }

    .icon-title-container,
    .icon {
        display: none;
    }

    .sub-container h1 {
        font-size: 2rem;
    }

    h2,
    h3,
    h4 {
        font-size: 0.7rem;
    }
    p {
        font-size: 0.5rem;
    }

    .p-container {
        max-height: 20vh;
    }

}
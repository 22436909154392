.nav {
    position: fixed;
    display: flex;
    align-items: flex-start;
    top: 3rem;
    gap: 2rem;
    left: 50%;
    transition: var(--transition);
    z-index: 0;
}

.nav-about {
    transform: translateX(-1.5rem);
}

.nav-skills {
    transform: translateX(-6rem);
}

.nav-projects {
    transform: translateX(-11rem);
}

.nav-contact {
    transform: translateX(-15rem);
}

a {
    width: 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.page-title {
    font-size: 0.8rem;
    color: white;
    cursor: default;
}

.nav-link {
    opacity: 0.5;
    transition: var(--transition);
}

.nav-link img {
    width: 4rem;
}

.nav-link:hover {
    opacity: 1;
}

.nav-link.current {
    opacity: 1;
    text-decoration: none;
}

@media (max-width: 1024px) {
    
    .nav-link {
        width: 3rem;
    }

    .nav-link img {
        width: 3rem;
    }
}

@media (max-width: 700px) {
    .nav-about, .nav-skills, .nav-contact, .nav-projects {
        transform: translateX(0);
    }
    
    .nav {
        top: 80vh;
        transform: translateX(-50%);
    }

    .nav-link {
        width: 2rem;
    }

    .nav-link img {
        width: 2rem;
    }
}
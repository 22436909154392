@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;700&display=swap');

.project-menu {
    width: 45rem;
    position: fixed;
    display: flex;
    top: 25%;
    left: 50%;
    transform: translateX(-60%);
    z-index: -3;
}

.project-items-container {
    flex-direction: column;
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 12rem;
    align-items: flex-end;
}

.project-item {
    height: 3.75rem;
    width: 68%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    font-family: 'Kanit', sans-serif;
}

.project-item.activeProject .title {
    font-size: 1.5rem;
}

.activeProject {
    border-radius: var(--radius-left);
    cursor: default;
}

.project-item.activeProject, .project-item.activeProject:hover {
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
    padding: 0 2rem;
}

.project-item:not(.activeProject) {
    opacity: 0.8;
}

.project-item:not(.activeProject):hover {
    opacity: 1;
}

.project-sub-container {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.project-sub-container h3 {
    position: relative;
    margin-bottom: 0;
}

.project-sub-container img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem 3rem 0.5rem 0.5rem;
    opacity: 0.9;
}

.project-sub-container p {
    font-size: 0.9rem;
}

.scrollbar-paragraph {
    overflow: auto;
    height: 25vh;
}

.link-container a {
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    width: 50%;
    height: 2.75rem;
    opacity: 0.8;
}

.link-container a:hover {
    opacity: 1;
    background: linear-gradient(90deg, #ffffff00, #ffffff42 50%, #ffffff00);
}

.link-container {
    display: flex;
    justify-content: space-around;
}

@media (max-width: 1024px) {
    .project-menu {
        width: 30rem;
        top: 20vh;
        transform: translateX(-50%);
    }

    .project-item {
        height: 2rem;
        width: 100%;
    }

    .project-item.activeProject .title {
        font-size: 1.2rem;
    }
    
    .title {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .project-menu {
        width: 20rem;
        top: 15%;
        transform: translateX(-60%);
    }

    .project-item {
        height: 2rem;
        width: 80%;
    }

    .project-item.activeProject .title {
        font-size: 0.7rem;
    }
    
    .project-item:not(.activeProject) .title {
        font-size: 0.5rem;
    }

    .project-sub-container {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    }

    .project-sub-container p {
        font-size: 0.6rem;
    }

    .scrollbar-paragraph {
        height: 35%;
        padding: 0.2rem;
        margin-top: 0.5rem;
    }
}
#bg {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
}

.shadow-overlay {
    object-fit: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -4;
    background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1024px) {
    .shadow-overlay {
        background-color: rgba(0, 0, 0, 0.55);
    }
}

@media (max-width: 700px) {
    .shadow-overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
}